import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { throwError, Observable, from, Subject } from 'rxjs';
import { Register } from './../../models/auth/register';
import { TokenStorageService } from './token-storage.service';
import { AccessData } from '../../models/auth/access-data';
import { Login } from '../../models/auth/login';
import { AuthService } from 'ngx-auth';
import { Result } from '../../models/helpers/result';
import { ActivationAccount } from '../../models/auth/activation-account';
import { ResendCode } from '../../models/auth/resend-code';
import { ForgottenPassword } from '../../models/auth/forgotten-password';
import { Router } from '@angular/router';
import { ResetPassword } from '../../models/auth/reset-password';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService implements AuthService {
	refresh$ = new Subject<boolean>();
	private apiUrl: string = environment.apiUrl + "api/auth/";

	constructor(private http: HttpClient,
		private tokenStorage: TokenStorageService,
		private router: Router) { }


	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	postLogin(login: Login): Observable<AccessData> {
		return this.http.post<Result>(this.apiUrl + "login", login).pipe(
			map((result: Result) => {
				if (result instanceof Array) {
					return result.pop();
				}
				
				return result;
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				return throwError(err);
			})
		);
	}

	postRegister(register: Register): Observable<AccessData> {
		return this.http.post<Result>(this.apiUrl + 'register', register).pipe(
			map((result: Result) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				return throwError(err);
			})
		);

	}

	postActivationAccount(activationAccount: ActivationAccount): Observable<Result> {
		return this.http.post<Result>(this.apiUrl + 'activation/account', activationAccount).pipe(
			map((result: Result) => {
				return result;
			}),
			catchError(err => {
				return throwError(err);
			})
		);
	}

	postResendCode(resendCode: ResendCode): Observable<Result> {
		return this.http.post<Result>(this.apiUrl + 'resend/code', resendCode).pipe(
			map((result: Result) => {
				return result;
			}),
			catchError(err => {
				return throwError(err);
			})
		);
	}

	postForgottenPassword(forgottenPassword: ForgottenPassword): Observable<Result> {
		return this.http.post<Result>(this.apiUrl + 'forgotten/password', forgottenPassword).pipe(
			map((result: Result) => {
				return result;
			}),
			catchError(err => {
				return throwError(err);
			})
		);
	}

	getResetPassword(userKey: string, code: string): Observable<Result> {

		const params = new HttpParams().set('userKey', userKey)
		                               .set('code', code)
		return this.http.get<Result>(this.apiUrl + 'reset/password', {params});
	}

	putResetPassword(resetPassword: ResetPassword): Observable<Result> {

		return this.http.put<Result>(this.apiUrl + 'reset/password', resetPassword);
	}

	/**
 * Function, that should perform refresh token verifyTokenRequest
 * @description Should be successfully completed so interceptor
 * can execute pending requests or retry original one
 * @returns {Observable<any>}
 */

	refreshToken(): Observable<Result> {
		return this.tokenStorage.getRefreshToken()
			.pipe(
				switchMap((refreshToken: string) => {
					const _login: Login = new Login();
					_login.grantType = "refresh_token";
					_login.refreshToken = refreshToken;
					return this.http.post<any>(this.apiUrl + "login", _login)
				}),
				map((result: any) => {
					console.log(result)
					if (result instanceof Array) {
						return result.pop();
					}
					return result;
				}),
				tap(this.saveAccessData.bind(this)),
				catchError(err => {
					this.logout(true);
					return throwError(err);
				})
			);
	}

	isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
 * Get access token
 * @description Should return access token in Observable from e.g. localStorage
 * @returns {Observable<string>}
 */
	getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}


	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	verifyTokenRequest(url: string): boolean {
		return url.endsWith("refresh");
	}

	logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		this.refresh$.next(true);
		if(refresh){
			this.router.navigate(['/']).then(()=> window.location.reload())	
			return;
		}

		this.router.navigate(['/']).then(()=> this.refresh$.next(true))	
	}

	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage
				.setAccessToken(accessData.accessToken)
				.setCreated(accessData.created)
				.setExpiration(accessData.expiration)
				.setRefreshToken(accessData.refreshToken)
				.setDisplayName(accessData.displayName)
				.setPermissions(accessData.profiles)

		}
	}


	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);

		};
	}

	refresh() {
		this.refresh$.next(true);
	}

}