import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControlDirective, FormControlName, FormGroup, Validators } from '@angular/forms';
import { FieldConfig } from 'src/app/core/models/components/field.interface';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];
  @Input() style?: string;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  form: FormGroup;

  get value() {
    return this.form.value;
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.createControl();
    this.formReady.emit(this.form);
    this.validationSpaceInput();
    this.validationUpperCase();

  }

  updateForm() {
    this.setValue();
    this.disable();
  }

  validationSpaceInput() {
    this.fields.filter((item) => item.type == 'input')
      .map((item) => {
        this.form.get(item.name).valueChanges
          .subscribe((value) => {
            this.form.get(item.name).setValue(value.replace(/^\s+/, ""), { emitEvent: false })
          })
      })
  }

  validationUpperCase() {
    this.fields.filter((item) => item.firstLetterUpperCase)
      .map((item) => {
        this.form.get(item.name).valueChanges
          .subscribe((value) => {
            const _formatedValue = value.charAt(0).toUpperCase() + value.slice(1);
            this.form.controls[item.name].setValue(_formatedValue, { emitEvent: false });
          })
      })
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
}

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.enable();
      this.submit.emit(this.form.value);
      this.disable();
    } else {
      this.validateAllFormFields(this.form);
      this.scrollIfFormHasErrors(this.form)
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
      if (field.disabled) {
        group.get(field.name).disable();
      }
    });
    return group;
  }

  getValue(type: string, value: any){
    if(type == 'currency'){
      return this.getFormattedPrice(value).replace("R$ ","");
    }
    return value;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  
  private setValue() {
    this.fields.filter((field) => field.name)
      .map((field) => {
        if(field.type == 'currency'){
          field.value = this.getFormattedPrice(field.value);
        }
        this.form.get(field.name).setValue(field.value, { emitEvent: false })
      })
  }

  private disable() {
    this.fields.filter((field) => field.name && field.disabled)
      .map((field) => {
        this.form.get(field.name).disable({ emitEvent: false })
      })
  }

  private enable() {
    this.fields.filter((field) => field.name)
      .map((field) => {
        this.form.get(field.name).enable({ emitEvent: false })
      })
  }

  scrollTo(el: Element) {
    if(el) { 
        el.scrollIntoView({ behavior: 'smooth' });
    }
 }
 
 scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
 }
 
 async scrollIfFormHasErrors(form: FormGroup): Promise <any> {
   await form.invalid;
   this.scrollToError();
 }

}
