<div *ngIf="!field.typeButton" [style]="field.btnStyle"  [formGroup]="group">
    <button [hidden]="field.hidden" [disabled]="field.disabled"  [class]="field.btnClass" mat-button >{{field.label}}</button>
</div>

<ng-container  *ngIf="field.typeButton == 'button'"   [formGroup]="group">
    <button *ngIf="!field.hidden" [disabled]="field.disabled"  [ngStyle]= "{'display': field.hidden ? 'none' : ''}"  [style]="field.btnStyle" [disabled]="field.disabled" (click)="action(field)" [type]="field.typeButton" [class]="field.btnClass" mat-button ><i [class]="field.iconBtn"  aria-hidden="true"></i> 
       <span>{{field.label}}</span> 
    </button>
    <div style="height: 0px;" *ngIf="field.lineBreak">
        <br/>
    </div>
</ng-container>
