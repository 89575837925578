import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from 'src/app/core/models/components/field.interface';
import { ComponentsService } from 'src/app/core/services/components/components.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;

  constructor(private componentsService: ComponentsService) { }

  ngOnInit(): void {

  }

  action(field) {
    this.componentsService.actionButton(field);
  }

}
