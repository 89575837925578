import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    
    constructor(private loadingService: LoaderService) {}
     
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const hidden: boolean = request.headers.get('hidden') == 'true';
        if(hidden){
            this.loadingService.hiddenContainer();
        }
        this.loadingService.showLoading();

        return next.handle(request).pipe((finalize(()=> {
            this.loadingService.hideLoading();
            if(hidden){
                setTimeout(()=>{
                    this.loadingService.showContainer()
                })
            }
        })))
    }
}