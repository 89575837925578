<mat-form-field  [ngStyle]="{'padding-bottom': hasError(field.name, field.validations) ? '5px' : null }" [hidden]="field.hidden" [style]="field.styleField" [formGroup]="group" [appearance]="field.appearance"  [floatLabel]="field.floatLabel">
        <mat-label>{{ field.label }}</mat-label>
        <i *ngIf="field.prefix" [class]="field.prefix" [style]="field.prefixStyle" style="left: 0;" aria-hidden="true"></i>&nbsp;
        <input  [dropSpecialCharacters]="field.dropSpecialCharacters" #inputRef [disabled]="'true'" [step]="field.step"    [min]="field.min" style="position: absolute;" [ngClass]="{'input': field.prefix || field.suffix}" autocomplete="off" [mask]="field.mask" matInput [formControlName]="field.name" [placeholder]="field.placeholder" [type]="field.inputType">
        <i *ngIf="field.suffix" (click)="showPassword()" [class]="field.suffix" style="right: 0;" [style]="field.suffixStyle" aria-hidden="true"></i>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error" >
        <mat-error  [style]="validation.style" *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
</mat-form-field>

<div style="height: 0px;" *ngIf="field.lineBreak">
    <br/>
</div>
