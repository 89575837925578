
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Color } from '../../models/colors/color';
import { ColorAdd } from '../../models/colors/color-add';
import { Result } from '../../models/helpers/result';

@Injectable({
  providedIn: 'root'
})
export class ColorsService {
  private apiUrl: string = environment.apiUrl + "api/colors";

  constructor(private http: HttpClient) { }

  getColors(): Observable<Color[]> {
    return this.http.get(this.apiUrl).pipe(
      map((result: Result) => result.data)
    )
  }

  postColor(colorAdd: ColorAdd): Observable<Color[]> {
    return this.http.post(this.apiUrl, colorAdd).pipe(
      map((result: Result) => result.data)
    )
  }
}
