import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from 'src/app/core/models/components/field.interface';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('inputRef', {static: true}) inputRef: ElementRef<HTMLElement>
  field: FieldConfig;
  group: FormGroup;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.setFocus();
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  setFocus() {
    if(this.field.focus && (this.field.disabled == false || !this.field.disabled)){
      setTimeout(()=>{ 
        this.inputRef.nativeElement.focus();
      },0);  
    }
  }

  hasError(control: string, validations: any[]){
    return validations.filter((validator)=> this.group.get(control).hasError(validator.name) && this.group.get(control).touched).length > 0
  }
 
  showPassword() {
    if(this.field.inputType == 'text') {
      this.field.inputType = 'password';
      this.field.suffix = 'fa fa-eye-slash';
    }
    else if(this.field.inputType = 'password') {
      this.field.inputType = 'text';
      this.field.suffix = 'fa fa-eye'
    }
  }

  onInputFocus(event) {
    const target = event.currentTarget;
  
    target.type = 'text';
    target.setSelectionRange(0, target.value.length);
    target.type = 'number';
  }

  formatNumberOfFollowers(value: any) {
    return
    const _value: string = value;
    // this.RegistroForm.controls['nMatricula'].setValue();
    this.group.controls[this.field.name].setValue(_value.replace(/[^\d]/, ''))

  }

  

}
