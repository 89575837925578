<mat-form-field [style]="field.styleField" [formGroup]="group" [appearance]="field.appearance"  [floatLabel]="field.floatLabel">
    <mat-label>{{ field.label }}</mat-label>
    <i *ngIf="field.prefix" [class]="field.prefix" [style]="field.prefixStyle" style="left: 0;" aria-hidden="true"></i>&nbsp;
    <input  currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left',allowNegative:'false' }" style="position: absolute;" [ngClass]="{'input': field.prefix || field.suffix}"  matInput [formControlName]="field.name" [placeholder]="field.placeholder" [type]="field.inputType"  matInput type="text" />
    <i *ngIf="field.suffix"  [class]="field.suffix" style="right: 0;" [style]="field.suffixStyle" aria-hidden="true"></i>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error" >
    <mat-error [style]="validation.style" *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
</ng-container>
</mat-form-field>

<div style="height: 0px;" *ngIf="field.lineBreak">
<br/>
</div>


