import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';

const color: string = '#e95d89'

@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
      primaryColour: color, 
      secondaryColour: color, 
      tertiaryColour: color,
    })
  ],
  exports:[LoaderComponent]
})
export class LoaderModule { }
