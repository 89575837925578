import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicFieldDirective } from '../dynamic-field/dynamic-field.directive';
import { ButtonComponent } from '../button/button.component';
import { InputComponent } from '../input/input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { LinkComponent } from '../link/link.component';
import { DateComponent } from '../date/date.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SelectComponent } from '../select/select.component';
import {MatSelectModule} from '@angular/material/select';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule } from '@angular/router';
import { TextAreaComponent } from '../text-area/text-area.component';
import {MatRadioModule} from '@angular/material/radio';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import  {MatCurrencyFormatModule} from 'mat-currency-format';
import { CurrencyComponent } from '../currency/currency.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectSearchComponent } from '../select-search/select-search.component';
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFieldDirective,
    ButtonComponent,
    InputComponent,
    LinkComponent,
    DateComponent,
    SelectComponent,
    TextAreaComponent,
    RadioButtonComponent,
    CurrencyComponent,
    ColorPickerComponent,
    SelectSearchComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    MatRadioModule,
    MatCurrencyFormatModule,
    NgxMatColorPickerModule,
    NgxMatSelectSearchModule,
    NgxCurrencyModule
    
  ],
  providers: [ { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
  exports: [
    DynamicFormComponent, 
    DynamicFieldDirective, 
    ButtonComponent,  
    InputComponent, 
    FormsModule, 
    ReactiveFormsModule, 
    MatFormFieldModule ,  
    MatInputModule,
    MatButtonModule,
    DateComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    ColorPickerComponent,
    SelectSearchComponent],
  entryComponents: [
    ButtonComponent,
    InputComponent,
    LinkComponent,
    DateComponent,
    SelectComponent,
    TextAreaComponent,
    RadioButtonComponent,
    CurrencyComponent,
    SelectSearchComponent
    
  ]
})
export class DynamicFormModule { }
