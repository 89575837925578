<mat-form-field [ngStyle]="{'display': field.hidden ? 'none' : ''}" [style]="field.styleField" [formGroup]="group"
    [appearance]="field.appearance" [floatLabel]="field.floatLabel">
    <mat-label>{{ field.label }}</mat-label>
    <i [hidden]="true" *ngIf="field.prefix" [class]="field.prefix" [style]="field.prefixStyle" style="left: 0;"
        aria-hidden="true"></i>&nbsp;
    <mat-select [disabled]="field.disabled || field.options.length == 0" *ngIf="!field.multiple" style="position: absolute; width: 100%;"
        [ngClass]="{'mat-select': field.prefix || field.suffix}" color="primary" [placeholder]="field.placeholder"
        [formControlName]="field.name">
        <mat-option *ngFor="let item of field.options" [value]="item.id"> {{item.label}}</mat-option>
    </mat-select>
    <mat-select [disabled]="field.disabled || field.options.length == 0" multiple *ngIf="field.multiple" style="position: absolute; width: 100%;"
        [ngClass]="{'mat-select': field.prefix || field.suffix}" color="primary" [placeholder]="field.placeholder"
        [formControlName]="field.name">
        <mat-option *ngFor="let item of field.options" [value]="item.id">
            {{item.label}}
        </mat-option>
    </mat-select>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error [style]="validation.style" *ngIf="group.get(field.name).hasError(validation.name)">
            {{validation.message}}</mat-error>
    </ng-container>
</mat-form-field>
<div style="height: 0px;" *ngIf="field.lineBreak">
    <br />
</div>