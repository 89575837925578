import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FieldConfig } from '../../models/components/field.interface';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {
  fieldAction = new Subject<FieldConfig>();
  constructor() { }

  actionButton(field: FieldConfig) {
    this.fieldAction.next(field);
  }
}
