import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { FieldConfig } from 'src/app/core/models/components/field.interface';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {
  search: string;
  filteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  field: FieldConfig;
  group: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions.next(this.field.options);
  }

  onChange(value) {
    if (!value) {
      this.filteredOptions.next(this.field.options);
      return;
    } else {
      value = value.toLowerCase();
    }
    this.filteredOptions.next(
      this.field.options.filter(item => item.label.toLowerCase().indexOf(value) > -1)
    );
  }

}
