<div class="container">
 <div class="header">
    <button (click)="closeModal()" class="btn-icon" type="button" *ngIf="data.closeBtn">
        <i class="fa fa-times icon" aria-hidden="true"></i>
       </button>
       <label  [ngClass]="data.type" >  <i [class]="data.icon" aria-hidden="true"></i> {{data.title}}</label>
   </div>
   <div class="content" [ngStyle]="data.contentStyle">
       <span>{{data.message}}</span>
       <dynamic-form [style]="'text-align: left;'" *ngIf="data.regConfig" #DynamicFormComponent  [fields]="data.regConfig">
    </dynamic-form>
   </div>
   <div class="footer">
       <div class="buttons"  [ngStyle]="{'justify-content': data.close ? 'space-between' : 'center' }">
        <button [ngStyle]="{'width': data.close ? '49%' : '50%' }" *ngIf="data.close" class="button align-center" [ngClass]="data.btnClass" style="color: var(--color-primary) !important;  background-color: white; border: 1px solid var(--color-primary); height: 36px; margin-right: 10px !important" (click)="closeModal()"  mat-button type="data.btnType"  >Não</button>
        <button  [ngStyle]="{'width': data.close ? '49%' : '50%' }" class="button align-center" [ngClass]="data.btnClass" (click)="onSubmit()"  mat-button type="data.btnType"  >{{data.btn}}</button>
       </div>
   </div>
</div>