import { ComponentFactoryResolver, Directive, Input, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from 'src/app/core/models/components/field.interface';
import { ButtonComponent } from '../button/button.component';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { CurrencyComponent } from '../currency/currency.component';
import { DateComponent } from '../date/date.component';
import { InputComponent } from '../input/input.component';
import { LinkComponent } from '../link/link.component';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { SelectSearchComponent } from '../select-search/select-search.component';
import { SelectComponent } from '../select/select.component';
import { TextAreaComponent } from '../text-area/text-area.component';

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  link: LinkComponent,
  date: DateComponent,
  select: SelectComponent,
  textArea: TextAreaComponent,
  radioButton: RadioButtonComponent,
  currency: CurrencyComponent,
  color: ColorPickerComponent,
  selectSearch: SelectSearchComponent,
  };

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  componentRef: any;

  constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
  ) { }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
  }

}
