import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ColorAdd } from 'src/app/core/models/colors/color-add';
import { FieldConfig } from 'src/app/core/models/components/field.interface';
import { Size } from 'src/app/core/models/sizes/size';
import { SizeAdd } from 'src/app/core/models/sizes/size-add';
import { ColorsService } from 'src/app/core/services/colors/colors.service';
import { ComponentsService } from 'src/app/core/services/components/components.service';
import { SizesService } from 'src/app/core/services/sizes/sizes.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

let _subscriptions$: Subscription[] = [];

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @ViewChild('DynamicFormComponent', { static: false }) dynamicFormComponent: DynamicFormComponent;
  fieldAction$: Observable<FieldConfig> = this.componentsService.fieldAction;

  constructor(private matDialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private componentsService: ComponentsService,
    private matDialog: MatDialog,
    private sizesService: SizesService,
    private colorsService: ColorsService) { }

  ngOnInit(): void {
    /*
        this.fieldAction$.subscribe((field) => {
      if (field.label == 'Tamanho') {
        const regConfig: FieldConfig[] = [
          {
            type: "input",
            label: field.label,
            inputType: "number",
            step: '1',
            min: 0,
            placeholder: this.getPlaceholderByLabel(field.label),
            appearance: 'outline',
            floatLabel: 'always',
            name: this.getNameByLabel(field.label),
            styleField: "width: 100%; padding-bottom: 10px",
            validations: [{
              name: "required",
              validator: Validators.required,
              message: field.label + " é obrigatório",
            },
            {
              name: "pattern",
              validator: Validators.pattern,
              message: field.label + " é obrigatório",
            }]
          },
          {
            type: "select",
            label: "Tipo",
            placeholder: 'Selecione o tipo',
            appearance: 'outline',
            floatLabel: 'always',
            name: 'size_typeID',
            styleField: "width: 100%; padding-bottom: 10px",
            options: [],
            validations: [{
              name: "required",
              validator: Validators.required,
              message: "Tipo é obrigatório",
            },]

          }
        ]
        this.sizesService.getSizeTypes()
          .subscribe((types) => {
            types.map((type) => regConfig[1].options.push({ id: type.size_TypeID, label: type.type }));
            regConfig[1].value = types[0].size_TypeID;
            this.openModal('300px', 'fa fa-plus-circle', 'Cadastrar ' + field.label.toLocaleLowerCase(), 'primary', 'Salvar', 'button-primary', regConfig, field.label);
          })
      }
      else if (field.label == 'Cor') {
        const regConfig: FieldConfig[] = [
          {
            type: "color",
            label: "Hex",
            placeholder: this.getPlaceholderByLabel(field.label),
            appearance: 'outline',
            floatLabel: 'always',
            name: 'hex',
            styleField: "width: 100%; padding-bottom: 10px",
            validations: [{
              name: "required",
              validator: Validators.required,
              message: "Cor é obrigatório",
            },
            {
              name: "pattern",
              validator: Validators.pattern(
                '^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$'
              ),
              message: "Cor é inválida.",
            }]
          },
          {
            type: "input",
            label: "Cor",
            inputType: "text",
            placeholder: "Digite o nome da cor",
            appearance: 'outline',
            floatLabel: 'always',
            name: "color",
            styleField: "width: 100%; padding-bottom: 10px",
            validations: [{
              name: "required",
              validator: Validators.required,
              message: field.label + " é obrigatório",
            }]
          }

        ]
        this.openModal('300px', 'fa fa-plus-circle', 'Cadastrar ' + field.label.toLocaleLowerCase(), 'primary', 'Salvar', 'button-primary', regConfig, field.label);

      }

    })
    */

  }

  private getNameByLabel(label: string) {
    switch (label) {
      case 'Tamanho':
        return 'size'
      case 'Cores':
        return 'color'
    }
  }

  private getPlaceholderByLabel(label: string) {
    switch (label) {
      case 'Tamanho':
        return 'Digite o Tamanho'
      case 'Cor':
        return 'Escola a cor ex. #00000'
    }
  }

  closeModal() {
    this.matDialogRef.close(this.data.redirect);
  }

  onSubmit() {
    if (this.data.btnType == 'submit' && this.dynamicFormComponent) {
      if (this.dynamicFormComponent.form.invalid) {
        Object.keys(this.dynamicFormComponent.form.controls).forEach((control) => {
          this.dynamicFormComponent.form.controls[control].markAsTouched();
        })
        return;
      }
      this.matDialogRef.close(this.dynamicFormComponent.form.value);
    }
    else if (this.data.close) {
      this.matDialogRef.close(true)
    }
    else {
      this.closeModal();
    }

  }

  private openModal(
    width: string,
    icon: string,
    title: string,
    type: string,
    btn: string,
    btnClass: string,
    regConfig: FieldConfig[],
    label: string) {
    const matDialog = this.matDialog.open(ModalComponent, {
      width: width,
      disableClose: false,
      data: { icon: icon, title: title, type: type, btn: btn, btnClass: btnClass, closeBtn: true, regConfig: regConfig, btnType: 'submit' }
    });
    matDialog.afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        if (label == 'Tamanho') {
          this.sizeAdd(result);
        }
        else if (label == 'Cor') {
          result.hex = result.hex.hex;
          this.colorAdd(result);
        }

      })
  }

  sizeAdd(sizeAdd: SizeAdd) {
    _subscriptions$.push(
      this.sizesService.postSize(sizeAdd)
        .subscribe(async (sizes: Size[]) => {
          await this.data.regConfig.filter((config) => config.name == 'sizeID')
            .map((config) => {
              sizes.map((size) => {
                config.options.push({ 'id': size.sizeID, 'label': size.size })
              })
            })
          this.dynamicFormComponent.form.get('sizeID').setValue(sizes[0].sizeID)
        })
    )
  }

  colorAdd(colorAdd: ColorAdd) {
    _subscriptions$.push(
      this.colorsService.postColor(colorAdd)
        .subscribe(async (colors) => {
          await this.data.regConfig.filter((config) => config.name == 'colorID')
            .map((config) => {
              colors.map((color) => {
                config.options.push({ 'id': color.colorID, 'label': color.color, 'style': `background-color: #${color.hex}` })
              })
            })
          this.dynamicFormComponent.form.get('colorID').setValue(colors[0].colorID)
        })
    )
  }

}
