import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/services/auth/token-interceptor';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { MatButtonModule } from '@angular/material/button';
import { LoaderModule } from './content/components/loader/loader.module';
import { LoaderInterceptor } from './core/services/loader/loader.interceptor';
import { AuthenticationModule } from './core/services/auth/authentication.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ModalModule } from './content/components/modal/modal.module';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
registerLocaleData(localePt)

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    MatButtonModule,
    ModalModule,
    LoaderModule,
    AuthenticationModule,
    NgxPermissionsModule.forRoot(),
    ModalModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
