import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Result } from '../../models/helpers/result';
import { Size } from '../../models/sizes/size';
import { SizeAdd } from '../../models/sizes/size-add';
import { SizeType } from '../../models/sizes/size-type';

@Injectable({
  providedIn: 'root'
})
export class SizesService {
  private apiUrl: string = environment.apiUrl + "api/sizes";

  constructor(private http: HttpClient) { }


  getSizes(): Observable<Size[]> {
    return this.http.get(this.apiUrl).pipe(
      map((result: Result) => result.data)
    )
  }

  getSizeTypes(): Observable<SizeType[]> {
    return this.http.get(this.apiUrl + "/types").pipe(
      map((result: Result) => result.data)
    )
  }

  postSize(sizeAdd: SizeAdd): Observable<Size[]> {
    return this.http.post(this.apiUrl, sizeAdd).pipe(
      map((result: Result) => result.data)
    )
  }
}
