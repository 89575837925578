import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from 'src/app/core/models/components/field.interface';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  date = new Date();
  constructor() { }

  ngOnInit(): void {
  }

  hasError(control: string, validations: any[]){
    return validations.filter((validator)=> this.group.get(control).hasError(validator.name) && this.group.get(control).touched).length > 0
  }
}
