import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './modal.component';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';



@NgModule({
  declarations: [ModalComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    DynamicFormModule
  ],
  entryComponents: [ModalComponent]
})
export class ModalModule { }
