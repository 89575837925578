<mat-form-field  [ngStyle]="{'padding-bottom': hasError(field.name, field.validations) ? '5px' : null }" [style]="field.styleField" [formGroup]="group" [appearance]="field.appearance"  [floatLabel]="field.floatLabel">
    <mat-label>{{ field.label }}</mat-label>
    <i *ngIf="field.prefix" [class]="field.prefix" [style]="field.prefixStyle" style="left: 0;" aria-hidden="true"></i>&nbsp;
    <input    [max]="date"  autocomplete="off"  (click)="picker.open()"   matInput [matDatepicker]="picker" [formControlName]="field.name" [placeholder]="field.placeholder">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [startView]="field.startView" #picker></mat-datepicker>
    <mat-hint></mat-hint>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
</mat-form-field>