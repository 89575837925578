import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/content/components/modal/modal.component';
import { Modal } from '../models/helpers/modal';
import { Router } from '@angular/router';
let matDialog: any;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private matDialog: MatDialog,
                private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((data) => {
            // Do your success stuff in here
            if(data instanceof HttpResponse) {
                if(data.body.modal) {
                    const _modal: Modal = data.body.modal;
                    this.openModal(_modal.message, _modal.width, _modal.icon, _modal.title, _modal.type, _modal.btnTitle, _modal.btnClass, data.body.redirect)
                }
            }
            
        }), catchError(error => {
            if (error instanceof HttpErrorResponse && (error.status === 200 || error.status == 401))  {
      
                return next.handle(request)
            }
            else {
                if (!matDialog) {
                    let message: string = "Não foi possivel finalizar a operação, caso o problema persista, entre em contato com o suporte!";
                    if (error.error.message) {
                        message = error.error.message
                    }
                    let _redirect: string  = null;
                    if(error.error.redirect) {
                        _redirect = error.error.redirect;
                    }
                    if(error.error.hiddenModal){
                        return throwError(error);
                    }
                    this.openModal(message, '420px', 'fa fa-times-circle', 'OPS!', 'danger', 'Fechar', 'button-danger', _redirect)
                }
                return throwError(error);
            }
        }));
    }

    private openModal(message: string, 
                      width: string,
                      icon: string,
                      title: string,
                      type: string,
                      btn: string,
                      btnClass: string,
                      redirect: string) {
        matDialog = this.matDialog.open(ModalComponent, {
            width: width,
            disableClose: true,
            data: {icon: icon ,title: title, message: message, type: type, btn: btn, btnClass: btnClass, redirect: redirect}
        });
        matDialog.afterClosed()
           .subscribe((redirect) => {
               if(redirect) {
                   this.router.navigate([redirect])
               }
               matDialog = undefined
            })
    }
}