import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = new Subject<boolean>();
  isHidden = new Subject<boolean>();

  constructor() { }

  showLoading() {
    this.isLoading.next(true);
  }

  hideLoading() {
    this.isLoading.next(false);
  }

  hiddenContainer() {
    setTimeout(()=>{
      this.isHidden.next(true)
    })

  }  

  showContainer() {
    this.isHidden.next(false);
  }

}
